<template>
  <div>

    <core-toolbar />

    <core-drawer />

    <core-view />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'GET_CURRENT_ACCOUNT'
    ]),
    role: function () {
      return this.GET_CURRENT_ACCOUNT.id
    }
  },
  mounted () {
    this.GET_USER_DATA()
  },
  methods: {
    ...mapActions([
      'GET_USER_DATA'
    ])
  }
}
</script>
<style lang="scss">
@import '@/styles/index.scss';

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
